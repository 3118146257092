import { useState } from "react"
import { useNavigate } from "react-router-dom"


const Support = () => {
    const navigate = useNavigate();

    const [count, setCount] = useState(50)
    const [Name, setName] = useState('')
    const [Surname, setSurname] = useState('')
    const [Middlename, setMiddlename] = useState('')
    const [EmailPhone, setEmailPhone] = useState('')
    const [Theme, setTheme] = useState('')
    const [Question, setQuestion] = useState('')

    const Counting = (e) => {
        setCount(50 - e.target.value.length)
        setTheme(e.target.value)
    }

    const pushQuest = async(e) => {
        e.preventDefault();

        const formData = {
            Name: Name,
            Surname: Surname,
            Middlename: Middlename,
            EmailPhone: EmailPhone,
            Theme: Theme,
            Question: Question
        };

        console.log(formData)
    
        const response = await fetch('https://hqua0323033.online-vm.com/support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
    
        const responseData = await response.json();
        console.log(responseData);

        navigate('/success-support');
    }

    return(
        <div style={{ overflow: 'hidden'}}>
            <title>Тех. Підтримка</title>
            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <div>
                    <h2 style={{margin: '10px'}}>Виникли проблеми чи запитання? Зателефонуйте нам: <span style={{textDecoration: 'underline'}}>(067)-455-94-34 / (067)-455-94-39</span></h2>
                    <h2>АБО</h2>
                    <h2 style={{margin: '10px'}}>Заповніть форму нижче, щоб ми як найшвидше з Вами зв'язались.</h2>
                    <form style={{display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '50px'}} onSubmit={pushQuest}>
                        <div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}} onChange={(e) => setName(e.target.value)}/>
                                <span className="bar"></span>
                                <label className="lbl">Ім'я*</label>
                            </div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}} onChange={(e) => setSurname(e.target.value)}/>
                                <span className="bar"></span>
                                <label className="lbl">Прізвище*</label>
                            </div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}} onChange={(e) => setMiddlename(e.target.value)}/>
                                <span className="bar"></span>
                                <label className="lbl">По батькові*</label>
                            </div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}} onChange={(e) => setEmailPhone(e.target.value)}/>
                                <span className="bar"></span>
                                <label className="lbl">Телефон/Пошта*</label>
                            </div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}}
                                    maxLength='50'
                                    onChange={Counting}
                                />
                                <span className="bar"></span>
                                <label className="lbl">Тема* (Залишилось символів: {count})</label>
                            </div>
                            <div className="groupSupp">      
                                <input type="text" className="inputWidthSupp" required style={{background: 'none', color: 'white'}} onChange={(e) => setQuestion(e.target.value)}/>
                                <span className="bar"></span>
                                <label className="lbl">Опишіть Ваше питання чи проблему*</label>
                            </div>
                            <div>
                            <button className="floating-button" type="submit" style={{float: "right", marginRight: '30px'}}>Продовжити</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )}

export {Support}

