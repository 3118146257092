import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/buy.css';

const Buy = (props) => {
    const navigate = useNavigate();

    const [Name, setName] = useState('');
    const [Surname, setSurname] = useState('');
    const [Middlename, setMiddlename] = useState('');
    const [EmailPhone, setEmailPhone] = useState('');

    const DoNext = async (e) => {
        e.preventDefault();

        navigate('/success');

        const formData = {
            Name: Name,
            Surname: Surname,
            Middlename: Middlename,
            EmailPhone: EmailPhone,
            Product: props.productName,
            Price: props.productPrice
        };
    
        const response = await fetch('https://hqua0323033.online-vm.com/get_order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
    
        const responseData = await response.json();
        console.log(responseData);
    }

    return (
        <form onSubmit={DoNext}>
            <div className="modal">
                <div className="modal-content" style={{ overflow: 'hidden' }}>
                    <h2 style={{ textAlign: 'center' }}>{props.productName}</h2>
                    <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>Ціна: {props.productPrice}грн</h4>
                    <p style={{ marginTop: '50px' }}>*Будь ласка, заповніть форму нижче:</p>
                    <div className="group" style={{ marginTop: '30px' }}>
                        <input
                            className='inputWidth'
                            type="text"
                            required
                            style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white' }}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label className='labelFont'>Ім'я</label>
                    </div>
                    <div className="group" style={{ marginTop: '30px' }}>
                        <input
                            className='inputWidth'
                            type="text"
                            required
                            style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white' }}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label className='labelFont'>Прізвище</label>
                    </div>
                    <div className="group" style={{ marginTop: '30px' }}>
                        <input
                            className='inputWidth'
                            type="text"
                            required
                            style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white' }}
                            onChange={(e) => setMiddlename(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label className='labelFont'>Адреса</label>
                    </div>
                    <div className="group" style={{ marginTop: '30px', marginBottom: '50px' }}>
                        <input
                            className='inputWidth'
                            type="text"
                            required
                            style={{ backgroundColor: 'rgb(87, 87, 87)', color: 'white' }}
                            onChange={(e) => setEmailPhone(e.target.value)}
                        />
                        <span className="bar"></span>
                        <label className='labelFont'>Номер телефону / Електронна пошта</label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className='floating-button' style={{marginLeft: '10px'}} onClick={props.closeWindow}>Назад</button>
                        <button
                            type='submit'
                            className='floating-button'
                            style={{ marginRight: '10px' }}
                        >
                            Далі
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export { Buy };
